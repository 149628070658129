.magic-thumb {
  border: none;
}
.magic-thumb:hover {
  filter: brightness(0.8);
  transition: border 100ms !important;
  transition: filter 100ms !important;
}
.magic-thumb>img {
  padding-bottom: 0 !important;
}
.magic-thumb-selected {
  border: 1px solid red !important;
}